body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  font-size: 1.4rem;
  margin: 30px 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root{
  /*used coolors.co palette*/
  --darkBlue: #1d3557;
  --lightBlue: #457b9d;
  --green: #a8dadc;
  --cream: #f4faf2;
  --red: #e63946;
  --shadow: 0 2px 15px #c5b9b9;
  --smallShadow:1px 0 5px #c5b9b9;
  --grey: #c3c3c3;
  --darkGrey: #5a5959;
  --darkGreyTransluscent: #5a5959e8;
  --lightgrey: #e9e9e9;
  box-sizing: border-box;
}

header {
  display: flex;
  position: fixed;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  background-color: #fff;
  justify-content: center;
  align-items: baseline;
  border-bottom: 1px solid var(--grey);
}
.deadTitle {
  padding-left: 4rem;
}
.deadTitle em, .deadTitle h1{
  z-index: 2000;
} 
.headerTitle {
  flex: none;
  align-self: flex-start;
  margin-right: 1.5em;
}
.headerTitle h1 {
  margin-left: -2em;
  margin-bottom: 0px;
}
.headerTitle p {
  margin-top: 0.125em;
  margin-right: -2em;
  text-align: right;
}
.headerNav {
  align-self: center;
  width: auto;
}
.headerNav ul {
  margin: 10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.links{
  list-style: none;
  text-decoration: none;
}
.links.desktop {
  margin: 0em 1.9em;
  display: inline-flex;
  color: black;
}
.links.mobile{
  color: white;
}
.headerNav li:hover,
button:hover {
  cursor: pointer;
}
.hamburger {
  position: absolute;
  /* always keep ham on top of everything */
  z-index:10001;
  top: 40px;
  right: 20px;
  width: 36px;
  height: 36px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  /* show the menu image */
  background-size: 100%;
}
.hamburger.menu{
  background-image:url("https://ljc-dev.github.io/testing0/ham.svg");
}
.hamburger.x{
  background-image:url("https://ljc-dev.github.io/testing0/ham-close.svg");
}

.navbar {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-right: 50px;
  top: 0;
  right: 0;
  background: linear-gradient(to right, black, rgba(0, 0, 0, 0.815), black);
  width: 30vw;
  height: 100vh;
  margin-top: 0;
  overflow: hidden;

  /* hide the menu right of the screen by default */
  
  /* transition adds a little animation when sliding in and out the menu */
  
}
.navbar.show{
  transform: translateX(0);
  color: white;
  transition: all .5s ease-in-out;
}
.navbar.hide{
  transform: translateX(100%);
}
.routes{
  position:relative;
}

.about .dead-card,.news-wrapper .dead-card,.resources .dead-card, .dataPage .dead-card{
  position:fixed;
  top:25px;
  right:20px;
  display:flex;
  flex-direction: column-reverse;
  font-size:.75em;
  align-items: baseline;
  justify-content: flex-start;
  width:210px;
  background:var(--lightgrey);
  color:var(--darkBlue);
  border:1px solid var(--grey) ;
  border-radius: 0 0 15px 15px ;
  transition:  all .5s ease-in-out;
  z-index: 1000;
  box-shadow: var(--smallShadow);
}
.about .dead-card:hover,.news-wrapper .dead-card:hover,.resources .dead-card:hover, .dataPage .dead-card:hover{
cursor: pointer;
}
.about .dead-card.visible, .news-wrapper .dead-card.visible, .resources .dead-card.visible, .dataPage .dead-card.visible{
top:145px
}
.people{
  display:flex;
  flex-wrap: wrap;
  width: fit-content;
}
.dead-card p{
  margin: 5px 10px 2px 14px;
  
}
.dead-card .person{
  width:25px;
  height:30px;
}
/*main*/

.loading{
  margin: 300px 0;
  display:flex;
  justify-content: center;
}

.homePage {
  padding-top: 0px;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(600x, 1fr));
  grid-template-areas:
    "hero hero hero"
    "info info info";
}

.heroContainer {
  grid-area: hero;
  padding-left: 20rem;
  margin-top: -12rem;
  width: fit-content;
  justify-self: center;
}
.heroContainer img {
  position: relative;
  box-shadow: var(--shadow);
  max-width: 800px;
  width: 100%;
  z-index: 1;
}
main .peopleCard {
  top: 45%;
  right: 40%;
  margin: 0px 150px 0px 0px;
  padding: 1em;
  position: relative;
  z-index: 2;
  max-width: 400px;
  min-width: 300px;
  height: auto;
  background-color: white;
  box-shadow: var(--shadow);
}
main .peopleCardInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0;
}

.homePage button,.resources button, .infoCard button {
  cursor: pointer;
  font-size: 1.2rem;
  margin: 1rem 0;
  background-color: var(--darkBlue);
  color: var(--lightgrey);
  height: 40px;
  text-align: center;
  border-radius: 10px;
  outline: none;
}
button:hover {
  color: var(--darkBlue);
  background-color: var(--lightgrey);
}
main .sinceVisit {
  border-top: 1px solid var(--grey);
}
main .infoCardContainer {
  grid-area: info;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.infoCard {
  flex: none;
  margin: 2em;
  height: 350px;
  max-width: 400px;
  width: 95%;
  box-shadow: var(--shadow);
}
.infoCard img{
width:100%;
height:100%;
}
.infoCard{
  padding:20px;
  padding-bottom: 100px;
  border:1.4px dotted var(--grey);
}
.resources button{
  margin-top:20px;

}
.who{
  position:relative;
}
.copywright{
position:absolute;
top:76%;
left:25px;
color:var(--lightgrey);
font-size: xx-small;
}
.news-wrapper{
  display:flex;
  flex-direction: column;
  align-items:center;
}
.single-article-wrapper{
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin:30px 0px;
}
.reverse{
  flex-direction: row-reverse;
}
.article-title{
  max-width: 640px;
  min-width: 300px;
  width:100%;
}
.about {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  margin-right: 15%;
  padding-top:45px;
}
.news-wrapper h1{
  font-family: 'Bodoni Moda', serif,'Old English';
}
.content {
  margin: 0 10px 50px 10px;
  padding: 1em;
  padding-left: 15%;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.content h3{
  align-self: center;
}
.resources,
.news-wrapper,
.about {
  margin-top: 200px;
}
.dataPage{
  margin-top: 290px;
}
.resources {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.slider-filter-wrapper{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
}
.slider, .filter-sort{
  display:flex;
  align-items: center;
  justify-content: center;
  transition:  all .5s ease-in-out;
  border-radius: 10px;
  width:500px;
  padding:.25em 1em;
  background:var(--darkBlue);
  color:var(--lightgrey);
}
.filter-sort{z-index: 99;}

.data-menu{
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:.75em;
    width:210px;
    border:1px solid var(--grey) ;
    border-radius: 0 0 15px 15px ;
    transition:  all .5s ease-in-out;
    z-index: 1000;
    box-shadow: var(--smallShadow);
    color:var(--darkBlue);
    background: var(--lightgrey);
    margin-right: 0;
    padding-bottom: 2px;
}
.data-menu:hover, .filter-sort:hover,.radios:hover, .radios label:hover{
  cursor:pointer;
  background:var(--lightgrey);
  color:var(--darkBlue); 
}

.menu-wrapper{
  position:fixed;
  display:flex;
  flex-direction: column;
  top:148px;
  left:20px;
  z-index:999;
  
}
.slider-filters{
  transition:  all .5s ease-in-out;
}
.slider {
  display: flex;
  flex-wrap: none;
  flex-direction: row;
  justify-content: flex-end;
  border-radius: 0;
  z-index: 100;
  margin-top:-30px;
  padding-top: 35px;
}
.slider h4{
  font-size:.65em;
  font-weight: 400;
}
.slide-track{
  position: relative;
  width:45px;
  height:17px;
  background:var(--lightgrey);
  border-radius:10px;
  margin:0 10px;
}
.slide {
  top: 1px;
  height: 5px;
  width: 5px;
  background:var(--lightgrey);
  border:5px solid var(--darkBlue);
  border-radius: 7.5px;
  position:absolute;
}
.slide:hover, .slide-track:hover{
  cursor: pointer;
}
.slide-us {
  left: 2px;
}
.slide-state {
  left: 28px;
}
.filter-sort{
   background:var(--darkBlue);
  color:var(--lightgrey);
  border-radius: 0 0 10px 10px;
}
.filter-sort.down{
  border-radius:0 0 0 0;
}


#menu.one, #sort.one{margin-right:30px;}
#menu.two,#sort.two{margin-left:30px;}
.data-radios{
  z-index:5;
  display:flex;
  flex-direction: column;
  width:500px;
  position:absolute;
  padding:1em;
  border-radius:0 0 10px 10px;
  transition:  all .5s ease-in-out;
  background:var(--darkBlue);
  color:var(--lightgrey); 
}
.dataPage polyline{
  stroke:var(--lightgrey);
  fill:transparent;
  stroke-width:7;
}
.data-menu polyline{
  stroke:var(--darkBlue);
}
.data-menu:hover polyline, .filter-sort:hover polyline{
  stroke:var(--darkBlue)
}
.data-radios.up {  
  transform: translatey(-200%);
 }
 .slider-filters.up{
  transform: translatey(-500px)
 }
.data-radios.down{
  overflow: hidden;
}
.data-radios input:hover{
  cursor: pointer;
}
.filter-sort:hover{
  cursor:pointer;
}

.arrow{height:max-content;width:max-content; }
#menu.arrow.down.one, #sort.arrow.down.one {
  transform:rotate(-180deg);
  transition:.5s;
}
#menu.arrow.down.two, #sort.arrow.down.two {
  transform:rotate(180deg);
  transition:.5s;
}
#menu.arrow.down.two, #menu.arrow.down.one {
  margin-top: -4px;
}
#menu.arrow.up.two, #menu.arrow.up.one {
  margin-bottom: -6px;
}
#menu.arrow.up.one, #sort.arrow.up.one {
  transform:rotate(0deg);
  transition:.5s;
}
#menu.arrow.up.two, #sort.arrow.up.two {
  transform:rotate(0deg);
  transition:.5s;
}
.arrow.down{
  transition:.5s;
}
.radios{
  display:flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 10px;
}
.radios input{
  height:20px;
  width:20px;
}
.radios span{
margin-left:5px;
}
.searchWrapper {
  z-index: 3;
  max-width: max-content;
  display: flex;
  flex-direction: column;
}

.searchWrapper input {
  width: 500px;
  height: 40px;
  font-size: 0.75em;
  border: 1px solid var(--grey);
  border-radius: 30px;
  outline: none;
  padding-left: 20px;
}
.suggestion {
  text-align: left;
  height: 30px;
  margin: 0;
  padding: 0 0 0 10px;
  border: 0;
  background-color: white;
  font-size: 0.75em;
  width: 100%;
}
.suggestion:hover {
  background-color: lightgray;
}

.searchfield ul,
.searchfield li {
  list-style: none;
  justify-content: left;
  padding: 0px;
  margin: 0px;
  width: 485px;
  border-radius: 20px;
}
.searchfield {
  z-index: 2;
  position: absolute;
  overflow-y: scroll;
  min-height: auto;
  max-height: 250px;
  width: 485px;
  margin: 43px;
  padding: 0;
  background-color: white;
  border: 1px solid var(--grey);
  border-top: 0px;
  border-radius: 0px 0px 10px 10px;
  margin-left: 18px;
}
/* hide scroll on search */
.searchfield::-webkit-scrollbar
 {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.searchfield{
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/*results from search*/
.stateData {
  z-index: 0;
}
.stateSearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: none;
  margin-top: -105px;
}

.stateDeaths,
.usDataComplete {
  padding-top: 5px;
  justify-content: auto;
  margin: 75px 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.stateDataCard,
.usDataCard {
  color: var(--black);
  padding: 1em;
  box-shadow: var(--shadow);
  background-color: var(--lightgrey);
  margin: 1em;
  border: 5px double rgb(41, 41, 41);
  font-size: .8em;
  color:var(--darkGrey);
}
footer {
  font-size: small;
}

.contact div{
  width:max-content;
  height: auto;
  text-decoration: underline;
}

.contact div:hover{
  cursor: pointer;
  color:blueviolet;
}
.netlify-form-fullpage{
  position:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;
  z-index: 100000;
  width:100vw;
  height: 100vh;
  background:var(--darkGreyTransluscent);
}
.netlify-form-wrapper{
  justify-content: center;
  align-items: center;
  max-width:500px;
  width:90vw;
  height:auto;
  font-size: 20px;
  position:relative;
}
.netlify-form-wrapper form{
  height:auto;
  padding:20px 50px;
  background:var(--lightgrey);
  color:var(--darkBlue);
}
.netlify-form-wrapper input{
  width:90%;
}
.netlify-form-wrapper button{
    cursor: pointer;
    font-size: 1.2rem;
    margin: 1rem 0;
    background-color: var(--darkBlue);
    color: var(--lightgrey);
    height: 30px;
    width:100px;
    text-align: center;
    border-radius: 10px;
    outline: none;
}

.netlify-form-wrapper p{
  display:flex;
  flex-direction: column;
}

.netlify-form-wrapper textarea{
  max-width:400px;
  width:100%;
  font-size: 15px;
}
.netlify-form-wrapper option{
  font-size: 15px;
}
.netlify-form-wrapper 
.form-cancel{
  position:absolute;
  bottom: 40px;
  left:175px;
}

.footer-main {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 5em;
  padding: 0px 1em;
  justify-content: center;
}
footer > span:first-of-type {
  display: flex;
  justify-content: center;
  margin: 1em;
}
.footer-main ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0px 1em;
  height: max-content;
  list-style: none;
  padding: 0;
}
.footer-main li {
  margin: 0px 1em;
}
.footer-credit {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 1em;
  font-size: small;
  justify-content: center;
}
.footer-credit .title {
  font-weight: 700;
}
.credits{
  display:flex;
  flex-direction: column;
}

.timer {
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.1px;
}
@media only screen and (max-width: 600px) {
  body {
    margin: 30px 20px;
  }
  .deadTitle {
    font-size: 60%;
    padding-left: 10px;
  }
  .routes {
    font-size: 75%;
  }
  .about .dead-card,.news-wrapper .dead-card,.resources .dead-card, .dataPage .dead-card{
    top:unset;
    bottom:-182px;
    flex-direction: column;
    border-radius: 15px 15px 0  0;
    width:155px
  }
  .about .dead-card.visible, .news-wrapper .dead-card.visible, .resources .dead-card.visible, .dataPage .dead-card.visible{
    top:unset;
    bottom:-2px;
  }
  .dead-card p{
    margin: 5px 0px 7px 12px ;
  }
  .stateDataCard,
.usDataCard {
  font-size: 17.92px;
}
  .heroContainer {
    padding: 0;
    margin-top: 75px;
    width: auto;
    max-width: 800px;
  }
  .resources {
    margin-top: 75px;
  }
  .dataPage{
    margin-top: 200px;
  }
  .news-wrapper,
  .about {
    margin-top: 100px;
  }
  
  .news-wrapper,
  .about {
    margin-left: 5%;
    margin-right: 5%;
  }
  .content {
    padding-left: 10%;
  }
  
  .slider-filter-wrapper{
    flex-direction: column-reverse;
  }
.slider{
  margin-top:-1px;
  align-items:flex-start;
  height: 90px;
  padding-top: 20px;
  
}
.slider h4{
  margin-top: 0;
}
.filter-sort.down{
  border-radius: 0;
}
.filter-sort.up{
  border-radius: 10px 10px 0 0 
}
.slider-filters.down{
  transform: translatey(-140px)
}
.slider-filters.up{
  transform: translateY(340px);
}
.data-radios.down {  
  transform: translatey(-426px);

  overflow: hidden;
  border-radius:10px 10px 0 0;
  height: 266px;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 50px;
}
.data-radios.up{
  transform:translateY(0px)
}
.data-radios{
  flex-direction: column-reverse;
}
.menu-wrapper{
  top:unset;
  bottom:-146px;
  z-index:999;
}
.data-menu{
  border-radius: 15px 15px 0  0;
  width:155px;
  padding-bottom: 5px;
  padding-top:5px;

}
.menu-wrapper svg{
  width:15px;
  height:10px;
}
#menu.arrow.down.two, #menu.arrow.down.one {
  margin-top: unset;
}
#menu.arrow.up.two, #menu.arrow.up.one {
  margin-bottom: unset;
}
#menu.arrow.down.one, #sort.arrow.down.one {
  transform:rotate(0deg);
}
#menu.arrow.down.two, #sort.arrow.down.two {
  transform:rotate(0deg);
}
#menu.arrow.up.one, #sort.arrow.up.one {
  transform:rotate(-180deg);
}
#menu.arrow.up.two, #sort.arrow.up.two {
  transform:rotate(180deg);
}
.slider, .filter-sort, .data-radios{
  width:300px;
}
.searchWrapper input {
  width: 275px;
    height: 30px;
  }
  
  .searchfield {
    max-height: 255px;
    width: 260px;
    margin-top: 33px;
  }
  .netlify-form-wrapper form {
    height:500px;
    font-size: 75%;
    padding: 20px 20px;
    background: var(--lightgrey);
    color: var(--darkBlue);
}
.netlify-form-wrapper button{
  margin:0;
}
.netlify-form-wrapper .form-cancel{
  bottom: 22px;
} 
}
@media only screen and (max-width:332px){
  .netlify-form-wrapper .form-cancel{
    bottom: 45px;
    left:130px;
  }
  .netlify-form-wrapper h2{
    font-size: 90%;
  }
}
@media only screen and (max-width: 1075px) and (min-width: 600px) {
  .heroContainer {
    margin-top: 200px;
  }
  .news-wrapper,
  .about {
    margin-left: 7%;
    margin-right: 7%;
  }
}
@media only screen and (max-width: 1075px) {
  .homePage {
    display: flex;
    flex-wrap: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .heroContainer {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    width: auto;
    max-width: 800px;
  }
  main .peopleCard {
    margin: 0;
    position: static;
    z-index: 0;
    max-width: 800px;
  }
  .main img {
    margin: 0;
    position: static;
    min-width: 300px;
  }
  .infoCard {
    margin: 2em;
  }
}

@media only screen and (max-width:350px){
    header {
      font-size: 75%;
    }
    .hamburger{
      top:20px;
      right:10px;
    }
    .slider,.filter-sort, .data-radios{
      width:250px;  }
    .data-radios{
      font-size: 75%;
      width: 259px;
    }
}